import React, { useEffect } from "react"
import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
import PropTypes from "prop-types"
import CarouselThumbnails from "./CarouselThumbnails"

export default function CarouselEfpa(props) {
  const {
    useDots,
    useArrows,
    className,
    animationSpeed,
    arrowRight,
    arrowLeft,
    itemWidth,
    slides,
    thumbnails,
    children,
    value,
    onChange,
  } = props
  useEffect(()=>{
    document.querySelectorAll('.BrainhubCarouselItem').forEach((item) =>{
      item.removeAttribute('style');
  });
  },[])

  return (
    <div className={`Carousel ${className}`}>
      <Carousel
        slides={slides}
        itemWidth={itemWidth}
        arrowLeft={arrowLeft}
        arrowRight={arrowRight}
        animationSpeed={animationSpeed}
        dots={useDots}
        arrows={useArrows}
        addArrowClickHandler={true}
        value={value}
        onChange={onChange}
      >
        {children}
      </Carousel>
      {thumbnails && (
        <CarouselThumbnails
          thumbnails={thumbnails}
          selected={value}
          onChange={onChange}
        />
      )}
    </div>
  )
}

CarouselEfpa.defaultProps = {
  className: "",
  useArrows: false,
  useDots: false,
}

CarouselEfpa.propTypes = {
  className: PropTypes.string,
  useArrows: PropTypes.bool,
  useDots: PropTypes.bool,
  animationSpeed: PropTypes.number,
  itemWidth: PropTypes.number,
  arrowLeft: PropTypes.node,
  arrowRight: PropTypes.node,
  children: PropTypes.node,
}
