import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"

const CarouselThumbnails = ({ thumbnails, selected, onChange }) => {
  const thumbs = useRef([])

  useEffect(() => {
    if (Array.isArray(thumbnails))
      thumbs.current = thumbs.current.slice(0, thumbnails.length)
  }, [thumbnails])

  useEffect(() => {
    if (thumbs.current[selected]) setThumbView(selected)
  }, [selected])

  const setThumbView = id =>
    thumbs.current[id].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "end",
    })

  const onNavigate = back => () => {
    if (!back && selected !== thumbnails.length - 1) onChange(selected + 1)
    if (back && selected) onChange(selected - 1)
  }

  const onClick = id => () => onChange(id)

  const renderThumbs = () =>
    Array.isArray(thumbnails) &&
    thumbnails.map((item, key) => {
      return (
        <button
          className={`CarouselThumbnails-item ${
            selected === key ? "active" : ""
          }`}
          key={key}
          ref={thumb => (thumbs.current[key] = thumb)}
          onClick={onClick(key)}
        >
          {typeof item === "object" ? (
            <img src={item.src} alt={item.alt} key={item.src + " " + key} />
          ) : (
            item
          )}
        </button>
      )
    })

  if (!Array.isArray(thumbnails)) return null

  return (
    <div className="CarouselThumbnails">
      <button
        className={`CarouselThumbnails-arrow ${
          selected === 0 ? "disabled" : ""
        }`}
        onClick={onNavigate(true)}
      >
        <img src="/imgs/galeria-ico-prev.png" alt="backBtn" />
      </button>
      <div className="CarouselThumbnails-items">{renderThumbs()}</div>
      <button
        className={`CarouselThumbnails-arrow right ${
          thumbnails.length - 1 === selected ? "disabled" : ""
        }`}
        onClick={onNavigate()}
      >
        <img src="/imgs/galeria-ico-next.png" alt="nextBtn" />
      </button>
    </div>
  )
}

CarouselThumbnails.propTypes = {
  thumbnails: PropTypes.array,
  selected: PropTypes.number,
  onChange: PropTypes.func,
}

export default CarouselThumbnails
